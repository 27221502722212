import React from "react"
import { graphql } from "gatsby"

import ParallaxHooklessImage from "../../components/parallax-hookless-image/ParallaxHooklessImage"
import TextBlock from "../../components/text-block/TextBlock"

import styles from "./index.module.css";
import { Head } from "../../components/head/Head"

export default ({ data }) => {
  const accommodationsRef = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    console.log(window.location);
    if (window.location.hash === "#accommodations") {
      accommodationsRef.current &&
        accommodationsRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
    }
  }, []);
  return (
    <React.Fragment>
      <Head title="Alex & Alexis - Wedding" imageSrc={data.alexisLooking.childImageSharp.fluid.src} />
      <ParallaxHooklessImage
        src={data.lumen.childImageSharp.fluid.src}
        height={data.lumen.childImageSharp.fluid.presentationHeight}
        width={data.lumen.childImageSharp.fluid.presentationWidth}
      >
        <h1>Wedding</h1>
      </ParallaxHooklessImage>
      <TextBlock>
        <h2>Details</h2>
        <div className={styles.detailsContainer}>
          <div className={styles.whenContainer}>
            <div>
              <h2>When</h2>
              Saturday <br />
              July 3, 2021 <br />
              5:00 pm
            </div>
          </div>
          <div className={styles.whereContainer}>
            <div>
              <h2>Where</h2>
              <a target="_blank" href="https://goo.gl/maps/m8GW8p9oy3jBiDTu7">
                Lumen<br />
                St. Louis
              </a>
            </div>
          </div>
        </div>
      </TextBlock>
      <ParallaxHooklessImage
        src={data.portrait.childImageSharp.fluid.src}
        height={data.portrait.childImageSharp.fluid.presentationHeight}
        width={data.portrait.childImageSharp.fluid.presentationWidth}
      />
      <TextBlock ref={accommodationsRef}>
        <h2 id="accommodations">Accommodations</h2>
        We have reserved a block of rooms at Hotel St. Louis in downtown<br />
        You can use this <a target="_blank" href="https://www.marriott.com/events/start.mi?id=1604428577548&key=GRP">link</a> to book a room by June 11th
      </TextBlock>
      <ParallaxHooklessImage
        src={data.alexisLooking.childImageSharp.fluid.src}
        height={data.alexisLooking.childImageSharp.fluid.presentationHeight}
        width={data.alexisLooking.childImageSharp.fluid.presentationWidth}
      />
    </React.Fragment>
  )
}

export const query = graphql`
  {
    lumen: file(relativePath: { eq: "lumen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }
    portrait: file(relativePath: { eq: "portrait.JPG" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }

    alexisLooking: file(relativePath: { eq: "alexis-looking.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3440) {
          src
          presentationHeight
          presentationWidth
        }
      }
    }
  }
`
